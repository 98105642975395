@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700&display=swap);

@charset "UTF-8";
@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mat-display-4 {
  font-size: 2.875rem;
  line-height: 3.375rem;
  font-weight: 700;
  font-family: "Fira Sans";
}
.mat-display-3 {
  font-size: 1.945rem;
  line-height: 1.975rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-display-2 {
  font-size: 1.45rem;
  line-height: 1.475rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-display-1 {
  font-size: 1.224rem;
  line-height: 1.362rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-display-0 {
  font-size: 1.124rem;
  line-height: 1.24rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-headline {
  font-size: 1.32rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-headline-2 {
  font-size: 1.18rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-title {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-subheading {
  font-size: 0.94rem;
  line-height: 1rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-body-2 {
  font-size: 0.891rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-body-1 {
  font-size: 0.891rem;
  line-height: 1.25rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-button {
  font-size: 1.04rem;
  line-height: 1rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-caption {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.h1-bold {
  font-size: 2.92rem;
  line-height: 2.92rem;
  font-weight: 700;
}
.h2-semibold {
  font-size: 2.14rem;
  line-height: 2.14rem;
  font-weight: 400;
}
.h3-semibold {
  font-size: 1.76rem;
  line-height: 1.76rem;
  font-weight: 400;
}
.h4-semibold {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.h5-semibold {
  font-size: 1.12rem;
  line-height: 1.12rem;
  font-weight: 400;
}
.headline-1-semibold {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
}
.headline-2-semibold {
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 400;
}
.title-1-semibold {
  font-size: 1.32rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.title-1-regular {
  font-size: 1.32rem;
  line-height: 1.5rem;
  font-weight: 300;
}
.title-2-bold {
  font-size: 1rem;
  line-height: 1.18rem;
  font-weight: 700;
}
.title-2-semibold {
  font-size: 1rem;
  line-height: 1.18rem;
  font-weight: 400;
}
.title-2-regular {
  font-size: 1rem;
  line-height: 1.18rem;
  font-weight: 300;
}
.title-3-bold {
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
}
.title-3-semibold {
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
}
.title-3-regular {
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 300;
}
.body-1-regular {
  font-size: 0.891rem;
  line-height: 1.5rem;
  font-weight: 300;
}
.body-1-semibold {
  font-size: 0.891rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.paragraph-1-semibold {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
}
.paragraph-2-semibold {
  font-size: 0.891rem;
  line-height: 0.891rem;
  font-weight: 400;
}
.paragraph-3-semibold {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
}
button,
.button-semibold {
  font-size: 1.05rem;
  line-height: 1.05rem;
  font-weight: 400;
}
.caption-regular {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 300;
}
.caption-semibold {
  font-size: 0.8rem;
  line-height: 0.8rem;
  font-weight: 400;
}
.text {
  filter: invert(8%) sepia(13%) saturate(2297%) hue-rotate(195deg) brightness(93%) contrast(90%);
}
.text-darker {
  filter: invert(98%) sepia(2%) saturate(2527%) hue-rotate(219deg) brightness(114%) contrast(85%);
}
body {
  --border-color: #d0d7de;
  --input-background-color: #f0f2f5;
  --text-color: #1c1e2f;
  --text-lighter-color: #b3b4b9;
  --text-darker-color: #ececec;
  --background-color: #f0f2f5;
  --background-darker-color: #24292f;
  --bar-color: #d3d4d6;
  --bar-darker-color: #56585d;
}
.primary {
  filter: invert(41%) sepia(43%) saturate(825%) hue-rotate(175deg) brightness(92%) contrast(92%);
}
body {
  --primary-color: #3970bc;
  --primary-lighter-color: #696969;
  --primary-100-color: #bdcfe8;
  --primary-200-color: #7b9fd2;
  --primary-darker-color: #13253e;
}
.accent {
  filter: invert(15%) sepia(92%) saturate(5646%) hue-rotate(110deg) brightness(101%) contrast(87%);
}
body {
  --accent-color: #107c10;
  --accent-lighter-color: #929292;
  --accent-100-color: #c8e4c8;
  --accent-200-color: #5fa75f;
  --accent-darker-color: #052905;
}
.warn {
  filter: invert(22%) sepia(51%) saturate(7045%) hue-rotate(345deg) brightness(91%) contrast(95%);
}
body {
  --warn-color: #e21e30;
  --warn-lighter-color: #484848;
  --warn-100-color: #f5b4ba;
  --warn-200-color: #eb6975;
  --warn-darker-color: #4e2327;
}
html {
  font-size: calc(20% + 0.75vw);
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html *,
html *::before,
html *::after {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}
@media (max-width: 1280px) {
  html {
    font-size: calc(80% + 0.25vw);
  }
}
@media (max-width: 600px) {
  html {
    font-size: calc(80% + 0.5vw);
  }
}
@media print {
  html {
    font-size: 100%;
    background-color: #fff;
  }
}
body {
  font-family: "Arial";
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
}
body a {
  text-decoration: none;
  color: var(--primary-color);
}
body h1,
body h2,
body h3,
body h4,
body p {
  margin-bottom: 0px;
  margin-block-end: 0px;
}
@media print {
  body {
    background-color: #fff;
  }
}
body main {
  min-height: 100vh;
  overflow: auto;
  width: 100%;
  text-align: start;
  margin: 0 auto;
  max-width: 1920px;
}
@media print {
  body main {
    background-color: #fff;
    display: grid;
    place-items: center;
    place-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
  }
}
body main *::-webkit-scrollbar {
  width: 10px;
}
body main *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
body main *::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 10px;
}
body .page-root {
  padding: 0.5rem 2vw;
  display: grid;
  grid-template-columns: 34vw minmax(10px, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  background-color: var(--background-color);
  overflow: hidden;
}
body .page-root .list-root {
  opacity: 0;
  animation: slide-left 0.6s ease-out 0.2s forwards;
}
body {
  --border-radius: 25px;
  --border-radius-low: 14px;
  --border-radius-high: 50px;
}
.paper {
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
}
.paper-low {
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-low);
}
.paper-high {
  box-shadow: 0px 0px 12px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-high);
}
.paper-inset {
  border-radius: var(--border-radius);
  box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #f8f8f8;
}
.paper-inset-low {
  border-radius: var(--border-radius-low);
  box-shadow: inset 3px 3px 6px #cbced1, inset -3px -3px 6px #f8f8f8;
}
.paper-inset-high {
  border-radius: var(--border-radius-high);
  box-shadow: 12px 12px 16px #cbced1, -12px -12px 16px #f8f8f8;
}
.paper-outset {
  border-radius: var(--border-radius);
  box-shadow: 8px 8px 8px #cbced1, -8px -8px 8px #f8f8f8;
}
.paper-outset-low {
  border-radius: var(--border-radius-low);
  box-shadow: 3px 3px 6px #cbced1, -3px -3px 6px #f8f8f8;
}
.paper-outset-high {
  border-radius: var(--border-radius-high);
  box-shadow: 12px 12px 16px #cbced1, -12px -12px 16px #f8f8f8;
}
.contained-button,
.outlined-button {
  cursor: pointer;
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-high);
  padding: 0.56rem 1.36rem;
  will-change: transform;
  transition: transform 0.32s ease-in-out;
}
.contained-button :active::before,
.outlined-button :active::before {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
.contained-button {
  color: var(--text-darker-color, #fff);
  background-color: var(--primary-color);
  border: none;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}
.contained-button::after {
  content: " ";
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.08) 10%, rgba(0, 0, 0, 0.01) 50%, rgba(0, 0, 0, 0.08) 90%, rgba(0, 0, 0, 0.16) 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 0px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  z-index: 1;
  will-change: max-width;
}
.contained-button:active::after {
  animation: ripple 1s ease-out forwards;
}
.contained-button:hover {
  box-shadow: 0px 0px 8px rgba(197, 138, 138, 0.25);
}
.contained-button:hover:disabled {
  box-shadow: unset;
}
.contained-button:disabled {
  background-color: var(--primary-lighter-color);
}
.contained-button:disabled::after {
  content: unset;
}
.contained-button:disabled[data-color=accent] {
  background-color: var(--accent-lighter-color);
}
.contained-button:disabled[data-color=warn] {
  background-color: var(--warn-lighter-color);
}
.contained-button[data-color=accent] {
  background-color: var(--accent-color);
}
.contained-button[data-color=warn] {
  background-color: var(--warn-color);
}
.contained-button[data-color=text] {
  background-color: var(--text-color);
}
.contained-button[data-color=none] {
  color: var(--text-color);
  background-color: inherit;
}
.contained-button[data-color=none]:hover {
  box-shadow: unset;
}
.contained-button > * {
  z-index: 2;
}
.outlined-button {
  color: var(--primary-color);
  background-color: transparent;
  border: 2px solid var(--primary-color);
  white-space: nowrap;
  border: 2px solid currentColor;
}
.outlined-button:hover {
  box-shadow: 0px 2px 8px rgba(68, 48, 48, 0.25);
  background-color: var(--primary-100-color);
}
.outlined-button:hover:disabled {
  box-shadow: unset;
}
.outlined-button:disabled {
  opacity: 0.5;
  background-color: var(--primary-100-color);
}
.outlined-button:disabled[data-color=accent] {
  background-color: var(--accent-lighter-color);
}
.outlined-button:disabled[data-color=warn] {
  background-color: var(--warn-lighter-color);
}
.outlined-button:disabled[data-color=text] {
  background-color: var(--border-color);
}
.outlined-button[data-color=accent] {
  color: var(--accent-color);
}
.outlined-button[data-color=accent]:hover {
  background-color: var(--accent-100-color);
}
.outlined-button[data-color=warn] {
  background-color: var(--warn-color);
}
.outlined-button[data-color=warn]:hover {
  background-color: var(--warn-100-color);
}
.outlined-button[data-color=text] {
  color: var(--text-color);
}
.outlined-button[data-color=text]:hover {
  background-color: var(--text-lighter-color);
}
.flat-button {
  border-radius: 50%;
  width: var(--icon-size, 3rem);
  height: var(--icon-size, 3rem);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  color: var(--text-darker-color, #fff);
  border: none;
  cursor: pointer;
  background-color: var(--primary-color);
}
.flat-button[data-color=accent] {
  background-color: var(--accent-color);
}
.flat-button[data-color=warn] {
  background-color: var(--warn-color);
}
.flat-button[data-color=text] {
  background-color: var(--text-color);
}
input:disabled {
  opacity: 0.7;
}
input.outlined,
select.outline,
textarea.outline {
  display: block;
  width: 100%;
  outline: none;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius-low);
  padding: 0.62rem 0.85rem;
  font-weight: 300;
  background: var(--input-background-color);
  color: currentColor;
}
input.outlined:not(:disabled):hover, input.outlined:not(:disabled):focus, input.outlined:not(:disabled):focus-within,
select.outline:not(:disabled):hover,
select.outline:not(:disabled):focus,
select.outline:not(:disabled):focus-within,
textarea.outline:not(:disabled):hover,
textarea.outline:not(:disabled):focus,
textarea.outline:not(:disabled):focus-within {
  border: 2px solid var(--primary-color);
}
input.outlined + span.error_message,
select.outline + span.error_message,
textarea.outline + span.error_message {
  visibility: hidden;
}
input.outlined.ng-valid.ng-touched.ng-dirty[data-validate],
select.outline.ng-valid.ng-touched.ng-dirty[data-validate],
textarea.outline.ng-valid.ng-touched.ng-dirty[data-validate] {
  border: 2px solid var(--accent-color);
}
input.outlined.ng-invalid.ng-touched.ng-dirty[data-validate],
select.outline.ng-invalid.ng-touched.ng-dirty[data-validate],
textarea.outline.ng-invalid.ng-touched.ng-dirty[data-validate] {
  color: var(--warn-color);
  border: 2px solid var(--warn-color);
}
input.outlined.ng-invalid.ng-touched.ng-dirty[data-validate] + span.error_message,
select.outline.ng-invalid.ng-touched.ng-dirty[data-validate] + span.error_message,
textarea.outline.ng-invalid.ng-touched.ng-dirty[data-validate] + span.error_message {
  visibility: visible;
  color: var(--warn-color);
}
input.outlined.ng-invalid.ng-touched.ng-dirty[data-error],
select.outline.ng-invalid.ng-touched.ng-dirty[data-error],
textarea.outline.ng-invalid.ng-touched.ng-dirty[data-error] {
  color: var(--warn-color);
  border: 2px solid var(--warn-color);
}
input.outlined.ng-invalid.ng-touched.ng-dirty[data-error] + span.error_message,
select.outline.ng-invalid.ng-touched.ng-dirty[data-error] + span.error_message,
textarea.outline.ng-invalid.ng-touched.ng-dirty[data-error] + span.error_message {
  visibility: visible;
  color: var(--warn-color);
}
label.checkbox {
  display: grid;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: var(--check-gap, 1.2ch);
}
label.checkbox > * {
  grid-row: 1;
}
label.checkbox input[type=checkbox] {
  grid-column: 1;
  place-self: center;
  font-size: 1.25rem;
  width: 1em;
  height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid #c1c1c1;
  border-radius: 4px;
  transition-duration: 0.3s;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
label.checkbox input[type=checkbox]:checked, label.checkbox input[type=checkbox]:hover {
  border: 2px solid var(--primary-color);
}
label.checkbox input[type=checkbox]:checked::after {
  content: "✓";
}
label.checkbox input[type=checkbox]::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-color);
  font-size: 0.75em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 700;
}
label.radio {
  --size: 1.48em;
  --border-radius: 4px;
  display: grid;
  grid-template-columns: -webkit-min-content -webkit-max-content -webkit-max-content;
  grid-template-columns: min-content max-content max-content;
  gap: var(--check-gap, 1.12ch);
}
label.radio > * {
  grid-row: 1;
}
label.radio input[type=radio] {
  grid-column: 1;
  place-self: center;
  font-size: 1.25rem;
  width: 1em;
  height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid #c1c1c1;
  border-radius: 50%;
  transition-duration: 0.3s;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
label.radio input[type=radio]:checked::after {
  content: " ";
}
label.radio input[type=radio]::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: var(--primary-color);
}
label.radio input[type=radio]:checked, label.radio input[type=radio]:hover {
  border: 2px solid var(--primary-color);
}
input[type=range] {
  --thumb-size: 1.35em;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  height: 1ch;
  background: var(--primary-100-color);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s, width 0.2s;
  border-radius: 4px;
}
input[type=range]:hover {
  opacity: 1;
  --thumb-size: 1.45em;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--thumb-size);
  aspect-ratio: 1/1;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: var(--thumb-size);
  aspect-ratio: 1/1;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
}
table {
  font-size: calc(0.72rem + 0.32vw);
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}
table[data-borders] th + th,
table[data-borders] td + td {
  border-left: 2px solid var(--border-color);
}
table th,
table td {
  padding: 0.54em;
  line-height: 1.15em;
}
table thead {
  background: var(--accent-200-color);
  color: var(--text-darker-color);
}
table thead tr th,
table thead tr td {
  text-align: left;
  text-transform: uppercase;
  line-height: 1.05em;
  font-weight: 600;
  font-size: 1.08em;
}
table tbody tr:nth-child(even) td {
  background-color: #4e91d411;
}
table tbody tr:hover {
  background-color: var(--border-color) !important;
}
.row {
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  gap: 0.42rem;
}
.row[data-inline] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-template-columns: repeat(12, auto);
}
.col-12 {
  grid-column: span 12;
}
.col-1 {
  grid-column: span 1;
}
.col-1[data-col-start="2"] {
  grid-column: 2/span 1;
}
.col-1[data-col-start="3"] {
  grid-column: 3/span 1;
}
.col-1[data-col-start="4"] {
  grid-column: 4/span 1;
}
.col-1[data-col-start="5"] {
  grid-column: 5/span 1;
}
.col-1[data-col-start="6"] {
  grid-column: 6/span 1;
}
.col-2 {
  grid-column: span 2;
}
.col-2[data-col-start="2"] {
  grid-column: 2/span 2;
}
.col-2[data-col-start="3"] {
  grid-column: 3/span 2;
}
.col-2[data-col-start="4"] {
  grid-column: 4/span 2;
}
.col-2[data-col-start="5"] {
  grid-column: 5/span 2;
}
.col-2[data-col-start="6"] {
  grid-column: 6/span 2;
}
.col-3 {
  grid-column: span 3;
}
.col-3[data-col-start="2"] {
  grid-column: 2/span 3;
}
.col-3[data-col-start="3"] {
  grid-column: 3/span 3;
}
.col-3[data-col-start="4"] {
  grid-column: 4/span 3;
}
.col-3[data-col-start="5"] {
  grid-column: 5/span 3;
}
.col-3[data-col-start="6"] {
  grid-column: 6/span 3;
}
.col-4 {
  grid-column: span 4;
}
.col-4[data-col-start="2"] {
  grid-column: 2/span 4;
}
.col-4[data-col-start="3"] {
  grid-column: 3/span 4;
}
.col-4[data-col-start="4"] {
  grid-column: 4/span 4;
}
.col-4[data-col-start="5"] {
  grid-column: 5/span 4;
}
.col-4[data-col-start="6"] {
  grid-column: 6/span 4;
}
.col-4[data-col-start="7"] {
  grid-column: 7/span 4;
}
.col-4[data-col-start="8"] {
  grid-column: 8/span 4;
}
.col-5 {
  grid-column: span 5;
}
.col-5[data-col-start="2"] {
  grid-column: 2/span 5;
}
.col-5[data-col-start="3"] {
  grid-column: 3/span 5;
}
.col-5[data-col-start="4"] {
  grid-column: 4/span 5;
}
.col-5[data-col-start="5"] {
  grid-column: 5/span 5;
}
.col-5[data-col-start="6"] {
  grid-column: 6/span 5;
}
.col-5[data-col-start="7"] {
  grid-column: 7/span 5;
}
.col-6 {
  grid-column: span 6;
}
.col-6[data-col-start="2"] {
  grid-column: 2/span 6;
}
.col-6[data-col-start="3"] {
  grid-column: 3/span 6;
}
.col-6[data-col-start="4"] {
  grid-column: 4/span 6;
}
.col-6[data-col-start="5"] {
  grid-column: 5/span 6;
}
.col-6[data-col-start="6"] {
  grid-column: 6/span 6;
}
.col-7 {
  grid-column: span 7;
}
.col-7[data-col-start="2"] {
  grid-column: 2/span 7;
}
.col-7[data-col-start="3"] {
  grid-column: 3/span 7;
}
.col-7[data-col-start="4"] {
  grid-column: 4/span 7;
}
.col-7[data-col-start="5"] {
  grid-column: 5/span 7;
}
.col-8 {
  grid-column: span 8;
}
.col-8[data-col-start="2"] {
  grid-column: 2/span 8;
}
.col-8[data-col-start="3"] {
  grid-column: 3/span 8;
}
.col-8[data-col-start="4"] {
  grid-column: 4/span 8;
}
.col-9 {
  grid-column: span 9;
}
.col-9[data-col-start="2"] {
  grid-column: 2/span 9;
}
.col-9[data-col-start="3"] {
  grid-column: 3/span 9;
}
.col-10 {
  grid-column: span 10;
}
.col-10[data-col-start="2"] {
  grid-column: 2/span 10;
}
.col-11 {
  grid-column: span 11;
}
@media print {
  .no-print,
.no-print * {
    display: none !important;
  }
}
